@import "~@/erp/styles/variables/variables.scss";



























































































































































































































.tab-content {
  overflow-y: auto;
  height: calc(100vh - 200px);
}
.account-group {
  margin-top: 20px;
  .title {
    font-size: 16px;
    color: #333;
    height: 40px;
    line-height: 40px;
  }
  .sign-content {
    width: 100%;
    overflow: hidden;
    ::v-deep img {
      max-width: 100px;
      max-height: 100px;
    }
  }
}
