@import "~@/erp/styles/variables/variables.scss";





























































































































































.mail-template-dialog {
  .el-select {
    width: 100%;
  }

  .editor {
    height: 360px;
  }
}
