/*! 2025-2-14 17:56:46 */
[data-v-c25afc54]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.mail-sign-dialog .el-select[data-v-c25afc54]{width:100%}.mail-sign-dialog .editor[data-v-c25afc54]{height:360px}

[data-v-576426da]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.tab-content[data-v-576426da]{overflow-y:auto;height:calc(100vh - 200px)}.account-group[data-v-576426da]{margin-top:20px}.account-group .title[data-v-576426da]{font-size:16px;color:#333;height:40px;line-height:40px}.account-group .sign-content[data-v-576426da]{width:100%;overflow:hidden}.account-group .sign-content[data-v-576426da]  img{max-width:100px;max-height:100px}

[data-v-31053ecc]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.mail-sign-dialog .editor[data-v-31053ecc]{height:400px}.mail-sign-dialog .inner[data-v-31053ecc]{display:flex}

[data-v-36189584]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.tab-content[data-v-36189584]{overflow-y:auto;height:calc(100vh - 200px)}.account-group[data-v-36189584]{margin-top:20px}.account-group .title[data-v-36189584]{font-size:16px;color:#333;height:40px;line-height:40px}.account-group .sign-content[data-v-36189584]{width:100%;overflow:hidden}.account-group .sign-content[data-v-36189584]  img{max-width:100px;max-height:100px}

[data-v-3aeb8fc9]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.mail-template-dialog .el-select[data-v-3aeb8fc9]{width:100%}.mail-template-dialog .editor[data-v-3aeb8fc9]{height:360px}

[data-v-7a22186e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.tab-content[data-v-7a22186e]{overflow-y:auto;height:calc(100vh - 200px)}.account-group[data-v-7a22186e]{margin-top:20px}.account-group .title[data-v-7a22186e]{font-size:16px;color:#333;height:40px;line-height:40px}.account-group .template-content[data-v-7a22186e]{width:100%;overflow:hidden}.account-group .template-content[data-v-7a22186e]  img{max-width:100px;max-height:100px}

[data-v-0b7de047]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.tab-content[data-v-0b7de047]{overflow-y:auto;height:calc(100vh - 200px);padding-top:20px}.setting-item[data-v-0b7de047]{display:flex;position:relative;align-items:center;margin-bottom:20px;font-size:14px;line-height:24px;padding-left:20px}.setting-item .el-checkbox[data-v-0b7de047]{margin-right:10px}.setting-item .el-form-item[data-v-0b7de047]{margin-bottom:0;margin-left:10px}.setting-item .desc[data-v-0b7de047]{color:#333}.setting-item .tips[data-v-0b7de047]{color:#999}.foot[data-v-0b7de047]{position:absolute;left:0;bottom:0;width:100%;text-align:center}


/*# sourceMappingURL=chunk-0f052c6e.58e3d4b2.css.map*/