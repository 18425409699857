@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































.mail-sign-dialog {
  .editor {
    height: 400px;
  }
  .inner {
    display: flex;
  }
}
