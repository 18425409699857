@import "~@/erp/styles/variables/variables.scss";


























































































































































































































.tab-content {
  overflow-y: auto;
  height: calc(100vh - 200px);
  padding-top: 20px;
}

.setting-item {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
  padding-left: 20px;

  .el-checkbox {
    margin-right: 10px;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-left: 10px;
    // width: 300px;
  }

  .desc {
    color: #333;
  }

  .tips {
    color: #999;
  }
}

.foot {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
